import React from 'react'
import Footer from '../Footer'
import Header from '../../header/Header'
function Contact() {
  return (
    <div>
      <Header/>
      <h2>this is Conact Page</h2>
      <Footer/>
    </div>
  )
}

export default Contact
