import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import Collapse from 'react-bootstrap/collapse';
import ALink from '../features/custom-link';
import Card from '..//features/accordion/card'
import SlideToggle from 'react-slide-toggle';
import { toDecimal, getTotalPrice } from '../../utils';
function Checkout(props) {
    const { cartList } = props;
    const [isCheckPayment, setCheckPayment] = useState(true);
    const [isCOD, setCOD] = useState(false);
    const [isOnlinePayment, setOnlinePayment] = useState(false);
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        companyName: '',
        country: 'us',
        address1: '',
        address2: '',
        city: '',
        state: '',
        zip: '',
        phone: '',
        email: '',
        createAccount: false,
        accountUsername: '',
        accountPassword: '',
        differentAddress: false,
        shippingFirstName: '',
        shippingLastName: '',
        shippingCompanyName: '',
        shippingCountry: 'us',
        shippingAddress1: '',
        shippingAddress2: '',
        shippingCity: '',
        shippingState: '',
        shippingZip: '',
        shippingPhone: '',
        orderNotes: '',
        shippingMethod: 'flat_rate',
        paymentMethod: '',
        termsCondition: false
    });
    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(formData); // You can handle the form submission here
        alert('called');
    };
    return (
        <main className="main checkout">
            <Helmet>
                <title>Riode React eCommerce Template | Checkout</title>
            </Helmet>

            <h1 className="d-none">Riode React eCommerce Template - Checkout</h1>

            <div className={`page-content pt-7 pb-10 ${cartList.length > 0 ? 'mb-10' : 'mb-2'}`}>
                <div className="step-by pr-4 pl-4">
                    <h3 className="title title-simple title-step"><ALink href="/pages/cart">1. Shopping Cart</ALink></h3>
                    <h3 className="title title-simple title-step active"><ALink href="#">2. Checkout</ALink></h3>
                    <h3 className="title title-simple title-step"><ALink href="/pages/order">3. Order Complete</ALink></h3>
                </div>
                <div className="container mt-7">
                    {
                        cartList.length > 0 ?
                            <>
                                <div className="card accordion">
                                    <Card type="parse" title="<div class='alert alert-light alert-primary alert-icon mb-4 card-header'>
                            <i class='fas fa-exclamation-circle'></i> <span class='text-body'>Returning customer?</span> <a href='#' class='text-primary collapse'>Click here to login</a>
                        </div>">
                                        <div className="alert-body collapsed">
                                            <p>If you have shopped with us before, please enter your details below.
                                                If you are a new customer, please proceed to the Billing section.</p>
                                            <div className="row cols-md-2">
                                                <form className="mb-4 mb-md-0">
                                                    <label htmlFor="username">Username Or Email *</label>
                                                    <input type="text" className="input-text form-control mb-0" name="username" id="username" autoComplete="username" />
                                                </form>
                                                <form className="mb-4 mb-md-0">
                                                    <label htmlFor="password">Password *</label>
                                                    <input className="input-text form-control mb-0" type="password" name="password" id="password" autoComplete="current-password" />
                                                </form>
                                            </div>
                                            <div className="checkbox d-flex align-items-center justify-content-between">
                                                <div className="form-checkbox pt-0 mb-0">
                                                    <input type="checkbox" className="custom-checkbox" id="signin-remember"
                                                        name="signin-remember" />
                                                    <label className="form-control-label" htmlFor="signin-remember">Remember
                                                        Me</label>
                                                </div>
                                                <ALink href="#" className="lost-link">Lost your password?</ALink>
                                            </div>
                                            <div className="link-group">
                                                <ALink href="#" className="btn btn-dark btn-rounded mb-4">Login</ALink> <span className="d-inline-block text-body font-weight-semi-bold">or Login With</span> <div className="social-links mb-4">
                                                    <ALink href="#" className="social-link social-google fab fa-google"></ALink>
                                                    <ALink href="#" className="social-link social-facebook fab fa-facebook-f"></ALink>
                                                    <ALink href="#" className="social-link social-twitter fab fa-twitter"></ALink>
                                                </div>
                                            </div>
                                        </div>
                                    </Card>
                                </div>
                                <div className="card accordion">
                                    <Card title="
                                            <div class='alert alert-light alert-primary alert-icon mb-4 card-header'>
                                                <i class='fas fa-exclamation-circle'></i>
                                                <span class='text-body'>Have a coupon?</span>
                                                <a href='#' class='text-primary'>Click here to enter your code</a>
                                            </div>" type="parse">

                                        <div className="alert-body mb-4 collapsed">
                                            <p>If you have a coupon code, please apply it below.</p>
                                            <div className="check-coupon-box d-flex">
                                                <input type="text" name="coupon_code" className="input-text form-control text-grey ls-m mr-4"
                                                    id="coupon_code" placeholder="Coupon code" />
                                                <button type="submit" className="btn btn-dark btn-rounded btn-outline">Apply Coupon</button>
                                            </div>
                                        </div>
                                    </Card>
                                </div>
                                <form onSubmit={handleSubmit} className="form">
                                    <div className="row">
                                        <div className="col-lg-7 mb-6 mb-lg-0 pr-lg-4">
                                            <h3 className="title title-simple text-left text-uppercase">Billing Details</h3>
                                            <div className="row">
                                                <div className="col-xs-6">
                                                    <label>First Name *</label>
                                                    <input type="text" className="form-control" name="firstName" value={formData.firstName} onChange={handleChange} required />
                                                </div>
                                                <div className="col-xs-6">
                                                    <label>Last Name *</label>
                                                    <input type="text" className="form-control" name="lastName" value={formData.lastName} onChange={handleChange} required />
                                                </div>
                                            </div>
                                            <label>Company Name (Optional)</label>
                                            <input type="text" className="form-control" name="companyName" value={formData.companyName} onChange={handleChange} />
                                            <label>Country / Region *</label>
                                            <div className="select-box">
                                                <select name="country" className="form-control" value={formData.country} onChange={handleChange}>
                                                    <option value="us">United States (US)</option>
                                                    <option value="uk">United Kingdom</option>
                                                    <option value="fr">France</option>
                                                    <option value="aus">Austria</option>
                                                </select>
                                            </div>
                                            <label>Street Address *</label>
                                            <input type="text" className="form-control" name="address1" value={formData.address1} onChange={handleChange} required placeholder="House number and street name" />
                                            <input type="text" className="form-control" name="address2" value={formData.address2} onChange={handleChange} placeholder="Apartment, suite, unit, etc. (optional)" />
                                            <div className="row">
                                                <div className="col-xs-6">
                                                    <label>Town / City *</label>
                                                    <input type="text" className="form-control" name="city" value={formData.city} onChange={handleChange} required />
                                                </div>
                                                <div className="col-xs-6">
                                                    <label>State *</label>
                                                    <input type="text" className="form-control" name="state" value={formData.state} onChange={handleChange} required />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-xs-6">
                                                    <label>ZIP *</label>
                                                    <input type="text" className="form-control" name="zip" value={formData.zip} onChange={handleChange} required />
                                                </div>
                                                <div className="col-xs-6">
                                                    <label>Phone *</label>
                                                    <input type="text" className="form-control" name="phone" value={formData.phone} onChange={handleChange} required />
                                                </div>
                                            </div>
                                            <label>Email Address *</label>
                                            <input type="email" className="form-control" name="email" value={formData.email} onChange={handleChange} required />

                                            <SlideToggle duration={300} collapsed>
                                                {({ onToggle, setCollapsibleElement }) => (
                                                    <div className="form-checkbox mb-0 pt-0">
                                                        <input type="checkbox" className="custom-checkbox" id="create-account" name="createAccount" checked={formData.createAccount} onChange={handleChange} onClick={onToggle} />
                                                        <label className='form-control-label ls-s' htmlFor='create-account'>Create an account?</label>
                                                        <div ref={setCollapsibleElement} style={{ overflow: 'hidden' }}>
                                                            <label htmlFor="account_username" className="pt-4">Account username&nbsp;
                                                                <abbr className="required" title="required">*</abbr>
                                                            </label>
                                                            <input type="text" className="form-control" name="accountUsername" id="account_username" value={formData.accountUsername} onChange={handleChange} placeholder="Username" />
                                                            <label htmlFor="account_password">Create account password&nbsp;
                                                                <abbr className="required" title="required">*</abbr>
                                                            </label>
                                                            <input type="password" className="form-control mb-3" name="accountPassword" id="account_password" value={formData.accountPassword} onChange={handleChange} placeholder="Password" />
                                                        </div>
                                                    </div>
                                                )}
                                            </SlideToggle>

                                            <SlideToggle duration={300} collapsed>
                                                {({ onToggle, setCollapsibleElement }) => (
                                                    <div className="form-checkbox mb-6">
                                                        <input type="checkbox" className="custom-checkbox" id="different-address" name="differentAddress" checked={formData.differentAddress} onChange={handleChange} onClick={onToggle} />
                                                        <label className='form-control-label ls-s' htmlFor='different-address'>Ship to a different address?</label>
                                                        <div ref={setCollapsibleElement} style={{ overflow: 'hidden' }}>
                                                            <div className="row pt-4">
                                                                <div className="col-xs-6">
                                                                    <label>First Name *</label>
                                                                    <input type="text" className="form-control" name="shippingFirstName" value={formData.shippingFirstName} onChange={handleChange} />
                                                                </div>
                                                                <div className="col-xs-6">
                                                                    <label>Last Name *</label>
                                                                    <input type="text" className="form-control" name="shippingLastName" value={formData.shippingLastName} onChange={handleChange} />
                                                                </div>
                                                            </div>
                                                            <label>Company Name (Optional)</label>
                                                            <input type="text" className="form-control" name="shippingCompanyName" value={formData.shippingCompanyName} onChange={handleChange} />
                                                            <label>Country / Region *</label>
                                                            <div className="select-box">
                                                                <select name="shippingCountry" className="form-control" value={formData.shippingCountry} onChange={handleChange}>
                                                                    <option value="us">United States (US)</option>
                                                                    <option value="uk">United Kingdom</option>
                                                                    <option value="fr">France</option>
                                                                    <option value="aus">Austria</option>
                                                                </select>
                                                            </div>
                                                            <label>Street Address *</label>
                                                            <input type="text" className="form-control" name="shippingAddress1" value={formData.shippingAddress1} onChange={handleChange} placeholder="House number and street name" />
                                                            <input type="text" className="form-control" name="shippingAddress2" value={formData.shippingAddress2} onChange={handleChange} placeholder="Apartment, suite, unit, etc. (optional)" />
                                                            <div className="row">
                                                                <div className="col-xs-6">
                                                                    <label>Town / City *</label>
                                                                    <input type="text" className="form-control" name="shippingCity" value={formData.shippingCity} onChange={handleChange} />
                                                                </div>
                                                                <div className="col-xs-6">
                                                                    <label>State *</label>
                                                                    <input type="text" className="form-control" name="shippingState" value={formData.shippingState} onChange={handleChange} />
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-xs-6">
                                                                    <label>ZIP *</label>
                                                                    <input type="text" className="form-control" name="shippingZip" value={formData.shippingZip} onChange={handleChange} />
                                                                </div>
                                                                <div className="col-xs-6">
                                                                    <label>Phone *</label>
                                                                    <input type="text" className="form-control" name="shippingPhone" value={formData.shippingPhone} onChange={handleChange} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </SlideToggle>

                                            <h2 className="title title-simple text-uppercase text-left mt-6">Additional Information</h2>
                                            <label>Order Notes (Optional)</label>
                                            <textarea className="form-control pb-2 pt-2 mb-0" cols="30" rows="5" name="orderNotes" value={formData.orderNotes} onChange={handleChange} placeholder="Notes about your order, e.g. special notes for delivery"></textarea>
                                        </div>

                                        <aside className="col-lg-5 sticky-sidebar-wrapper">
                                            <div className="sticky-sidebar mt-1" data-sticky-options="{'bottom': 50}">
                                                <div className="summary pt-5">
                                                    <h3 className="title title-simple text-left text-uppercase">Your Order</h3>
                                                    <table className="order-table">
                                                        <thead>
                                                            <tr>
                                                                <th>Product</th>
                                                                <th></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                cartList.map(item =>
                                                                    <tr key={'checkout-' + item.name}>
                                                                        <td className="product-name">{item.name} <span className="product-quantity">×&nbsp;{item.qty}</span></td>
                                                                        <td className="product-total text-body">${toDecimal(item.price * item.qty)}</td>
                                                                    </tr>
                                                                )
                                                            }

                                                            <tr className="summary-subtotal">
                                                                <td>
                                                                    <h4 className="summary-subtitle">Subtotal</h4>
                                                                </td>
                                                                <td className="summary-subtotal-price pb-0 pt-0">${toDecimal(getTotalPrice(cartList))}
                                                                </td>
                                                            </tr>
                                                            <tr className="sumnary-shipping shipping-row-last">
                                                                <td colSpan="2">
                                                                    <h4 className="summary-subtitle">Calculate Shipping</h4>
                                                                    <ul>
                                                                        <li>
                                                                            <div className="custom-radio">
                                                                                <input type="radio" id="flat_rate" name="shippingMethod" className="custom-control-input" value="flat_rate" checked={formData.shippingMethod === 'flat_rate'} onChange={handleChange} />
                                                                                <label className="custom-control-label" htmlFor="flat_rate">Flat rate</label>
                                                                            </div>
                                                                        </li>

                                                                        <li>
                                                                            <div className="custom-radio">
                                                                                <input type="radio" id="free-shipping" name="shippingMethod" className="custom-control-input" value="free_shipping" checked={formData.shippingMethod === 'free_shipping'} onChange={handleChange} />
                                                                                <label className="custom-control-label" htmlFor="free-shipping">Free shipping</label>
                                                                            </div>
                                                                        </li>

                                                                        <li>
                                                                            <div className="custom-radio">
                                                                                <input type="radio" id="local_pickup" name="shippingMethod" className="custom-control-input" value="local_pickup" checked={formData.shippingMethod === 'local_pickup'} onChange={handleChange} />
                                                                                <label className="custom-control-label" htmlFor="local_pickup">Local pickup</label>
                                                                            </div>
                                                                        </li>
                                                                    </ul>
                                                                </td>
                                                            </tr>
                                                            <tr className="summary-total">
                                                                <td className="pb-0">
                                                                    <h4 className="summary-subtitle">Total</h4>
                                                                </td>
                                                                <td className=" pt-0 pb-0">
                                                                    <p className="summary-total-price ls-s text-primary">${toDecimal(getTotalPrice(cartList))}</p>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    <div className="payment accordion radio-type">
                                                        <h4 className="summary-subtitle ls-m pb-3">Payment Methods</h4>

                                                        <div className="checkbox-group">
                                                            <div className="card-header">
                                                                <ALink href="#" className={ `text-body text-normal ls-m ${ isCheckPayment && !isCOD && !isOnlinePayment ? 'collapse' : '' }` } onClick={ () => { !isCheckPayment && setCheckPayment( !isCheckPayment );setCOD(false);setOnlinePayment(false)  } }>Check payments</ALink>
                                                            </div>

                                                            <Collapse in={ isCheckPayment }>
                                                                <div className="card-wrapper">
                                                                    <div className="card-body ls-m overflow-hidden">
                                                                        Please send a check to Store Name, Store Street,
                                                                        Store Town, Store State / County, Store Postcode.
                                                                    </div>
                                                                </div>
                                                            </Collapse>

                                                            <div className="card-header">
                                                                <ALink href="#" className={ `text-body text-normal ls-m ${ !isCheckPayment && isCOD && !isOnlinePayment ? 'collapse' : '' }` } onClick={ () => { isCheckPayment && setCheckPayment( !isCheckPayment ); setCOD(true);setOnlinePayment(false) } }>Cash on delivery</ALink>
                                                            </div>

                                                            <Collapse in={ isCOD }>
                                                                <div className="card-wrapper">
                                                                    <div className="card-body ls-m overflow-hidden">
                                                                        Please send a check to Store Name, Store Street,
                                                                        Store Town, Store State / County, Store Postcode.
                                                                    </div>
                                                                </div>
                                                            </Collapse>


                                                            <div className="card-header">
                                                                <ALink href="#" className={ `text-body text-normal ls-m ${ !isCheckPayment && !isCOD && isOnlinePayment ? 'collapse' : '' }` } onClick={ () => { isCheckPayment && setCheckPayment( !isCheckPayment ); setCOD(false);setCheckPayment(false);setOnlinePayment(true) } }>Online Payment</ALink>
                                                            </div>

                                                            <Collapse in={ isOnlinePayment }>
                                                                <div className="card-wrapper">
                                                                    <div className="card-body ls-m overflow-hidden">
                                                                        Please send a check to Store Name, Store Street,
                                                                        Store Town, Store State / County, Store Postcode.
                                                                    </div>
                                                                </div>
                                                            </Collapse>
                                                        </div>
                                                    </div>
                                                    <div className="form-checkbox mt-4 mb-5">
                                                        <input type="checkbox" className="custom-checkbox" id="terms-condition" name="termsCondition" checked={formData.termsCondition} onChange={handleChange} />
                                                        <label className="form-control-label" htmlFor="terms-condition">
                                                            I have read and agree to the website <ALink href="#">terms and conditions </ALink>*
                                                        </label>
                                                    </div>
                                                    <button type="submit" onClick={()=>{
                                                        console.log(JSON.stringify(formData))
                                                    }} className="btn btn-dark btn-rounded btn-order">Place Order</button>
                                                </div>
                                            </div>
                                        </aside>
                                    </div>
                                </form>

                            </>
                            :
                            <div className="empty-cart text-center">
                                <p>Your cart is currently empty.</p>
                                <i className="cart-empty d-icon-bag"></i>
                                <p className="return-to-shop mb-0">
                                    <ALink className="button wc-backward btn btn-dark btn-md" href="/shop">
                                        Return to shop
                                    </ALink>
                                </p>
                            </div>
                    }
                </div>
            </div>
        </main>
    )
}

function mapStateToProps(state) {
    return {
        cartList: state.cart.data ? state.cart.data : []
    }
}

export default connect(mapStateToProps)(Checkout);